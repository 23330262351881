import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { SnackbarContext } from "../../../../context/snackbar";
import { Composition } from "../../../../models/composition/composition";
import { Song } from "../../../../models/song/song";
import { PlayCircleOutline } from "@mui/icons-material";
import CustomToolbar from "../../../../components/layout/titles/CustomToolbar";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DefaultReasons {
  static IncorrectCAE = "Incorrect CAE/IPI number provided";
  static IncorrectPRO = "Incorrect PRO affiliation selected";
  static SongwriterArtist = "Songwriter name appears to be an artist name";
  static LyricalNotAccurate = "Lyrical theme(s) are not accurate";
  static MoodNotAccurate = "Mood(s) are not accurate";
  static GenreNotAccurate = "Genre(s) are not accurate";
  static InstrumentalLyrics =
    "You've selected 'Instrumental' as a tag but have provided lyrics. If you have submitted lyrics, your song cannot be classed as instrumental and vice versa";
}

export default function UnsubmitSongModal({
  modalOpened,
  handleClose,
  compositionId,
  setSongs,
}) {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [incorrectCAE, setIncorrectCAE] = useState({
    checked: false,
    message: DefaultReasons.IncorrectCAE,
  });
  const [incorrectPRO, setIncorrectPRO] = useState({
    checked: false,
    message: DefaultReasons.IncorrectPRO,
  });
  const [songwriterArtist, setSongwriterArtist] = useState({
    checked: false,
    message: DefaultReasons.SongwriterArtist,
  });
  const [lyricalNotAccurate, setLyricalNotAccurate] = useState({
    checked: false,
    message: DefaultReasons.LyricalNotAccurate,
  });
  const [moodNotAccurate, setMoodNotAccurate] = useState({
    checked: false,
    message: DefaultReasons.MoodNotAccurate,
  });
  const [genreNotAccurate, setGenreNotAccurate] = useState({
    checked: false,
    message: DefaultReasons.GenreNotAccurate,
  });
  const [instrumentalWithLyrics, setInstrumentalWithLyrics] = useState({
    checked: false,
    message: DefaultReasons.InstrumentalLyrics,
  });
  const [usingOtherReason, setUsingOtherReason] = useState(false);
  const [customReason, setCustomReason] = useState("");

  const closeSongModal = () => {
    handleClose();
  };

  const handleChange = (input) => {
    setCustomReason(input.target.value);
  };

  const processMessage = () => {
    let message = usingOtherReason ? customReason : "";
    const defaultReasons = [
      incorrectCAE,
      incorrectPRO,
      songwriterArtist,
      lyricalNotAccurate,
      moodNotAccurate,
      genreNotAccurate,
      instrumentalWithLyrics,
    ];
    defaultReasons.forEach((reason, index) => {
      if (reason.checked) {
        if (message.length !== 0) {
          message += "\n\n";
        }
        message += reason.message;
      }
    });
    return message;
  };

  const handleSave = () => {
    startGlobalLoader();
    const message = processMessage();
    API.post(ApiUri.COMPOSITION + "/" + compositionId + ApiUri.UNSUBMIT, {
      message: message,
    }).then(
      (response) => {
        setSongs(null);
        stopGlobalLoader();
        initSnackbarSuccess("Song successfully unsubmited");
        closeSongModal();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        closeSongModal();
      }
    );
  };

  const handleOther = (event) => {
    setUsingOtherReason(event.target.checked);
  };

  const formIsValid = usingOtherReason
    ? customReason.length > 0
    : incorrectCAE ||
      incorrectPRO ||
      songwriterArtist ||
      lyricalNotAccurate ||
      moodNotAccurate ||
      genreNotAccurate ||
      instrumentalWithLyrics;

  return (
    <React.Fragment>
      <Dialog
        open={modalOpened}
        onClose={closeSongModal}
        TransitionComponent={Transition}
        sx={{ m: "40px" }}
        maxWidth="md"
      >
        <AppBar position="sticky">
          <CustomToolbar>
            <Typography variant="h6" flex={1}>
              Unsubmit Song
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeSongModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Grid container p={4} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Reason for Unsubmission</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={incorrectCAE.checked}
                    onChange={() =>
                      setIncorrectCAE({
                        ...incorrectCAE,
                        checked: !incorrectCAE.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label={DefaultReasons.IncorrectCAE}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={incorrectPRO.checked}
                    onChange={() =>
                      setIncorrectPRO({
                        ...incorrectPRO,
                        checked: !incorrectPRO.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label={DefaultReasons.IncorrectPRO}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={songwriterArtist.checked}
                    onChange={() =>
                      setSongwriterArtist({
                        ...songwriterArtist,
                        checked: !songwriterArtist.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label={DefaultReasons.SongwriterArtist}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lyricalNotAccurate.checked}
                    onChange={() =>
                      setLyricalNotAccurate({
                        ...lyricalNotAccurate,
                        checked: !lyricalNotAccurate.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label={DefaultReasons.LyricalNotAccurate}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={moodNotAccurate.checked}
                    onChange={() =>
                      setMoodNotAccurate({
                        ...moodNotAccurate,
                        checked: !moodNotAccurate.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label={DefaultReasons.MoodNotAccurate}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={genreNotAccurate.checked}
                    onChange={() =>
                      setGenreNotAccurate({
                        ...genreNotAccurate,
                        checked: !genreNotAccurate.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label={DefaultReasons.GenreNotAccurate}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={instrumentalWithLyrics.checked}
                    onChange={() =>
                      setInstrumentalWithLyrics({
                        ...instrumentalWithLyrics,
                        checked: !instrumentalWithLyrics.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label={DefaultReasons.InstrumentalLyrics}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={usingOtherReason}
                    onChange={handleOther}
                    color="primary"
                  />
                }
                label="Other"
              />
            </FormGroup>
          </Grid>
          {usingOtherReason && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Unsubmission reason"
                value={customReason}
                onChange={handleChange}
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <ActionButtonSmall onClick={handleClose}>
                Cancel
              </ActionButtonSmall>
              <ActionButtonSmall disabled={!formIsValid} onClick={handleSave}>
                Confirm
              </ActionButtonSmall>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
