import React, { useContext, useState } from "react";
import { Composition } from "../../../../models/composition/composition";
import {
  Fab,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from "@mui/material";
import H2 from "../../../../components/layout/titles/h2";
import FormStepOne from "./form-step-one";
import FormStepTwo from "./form-step-two";
import API from "../../../../api/api";
import ApiUri from "../../../../api/api-uri";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import DeliverySplitsPreviewDialog from "../../../../components/composition/delivery-splits-preview-dialog";
import { AuthContext } from "../../../../context/auth";
import { CallSplit, LyricsRounded, VolumeUpRounded } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

interface CompositionFormProps {
  mode: "create" | "edit";
  composition: Composition;
  setComposition: React.Dispatch<React.SetStateAction<Composition>>;
  setActiveGlobalStep?: React.Dispatch<React.SetStateAction<number>>;
}

const CompositionForm: React.FC<CompositionFormProps> = ({
  mode,
  composition,
  setComposition,
  setActiveGlobalStep,
}) => {
  const [activeStep, setActiveStep] = useState(mode === "create" ? 0 : 1);
  const steps = ["General", "Songwriters"];
  const [deliverySplitPreviewDialogOpen, setDeliverySplitPreviewDialogOpen] =
    useState(false);
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <FormStepOne composition={composition} handleSave={handleSave} />
        );
      case 1:
        return (
          <FormStepTwo
            composition={composition}
            handleSave={handleSave}
            mode={mode}
            setActiveStep={setActiveStep}
          />
        );
    }
  };

  const handleStepLabelClick = (step: number) => {
    if (mode === "edit") {
      if (step === activeStep) {
        setActiveStep(-1);
        return;
      }

      setActiveStep(step);
    } else if (mode === "create") {
      if (step === 0) {
        setActiveStep(step);
        return;
      }
    }
  };

  const closeDeliverySplitsPreviewDialog = () => {
    setDeliverySplitPreviewDialogOpen(false);
  };

  const handleSave = (step: number, currentComposition: Composition) => {
    startGlobalLoader();
    setComposition(currentComposition);
    const newComposition = currentComposition;
    switch (step) {
      case 0:
        switch (mode) {
          case "create":
            if (newComposition.song_writers) {
              API.put(
                ApiUri.COMPOSITION + "/" + newComposition.id,
                newComposition
              ).then(
                (response) => {
                  setComposition(response.data);
                  stopGlobalLoader();
                  initSnackbarSuccess("Composition data successfully edited");
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                },
                (err) => {
                  stopGlobalLoader();
                  initSnackbarError(err);
                }
              );
            } else {
              API.post(ApiUri.COMPOSITION, newComposition).then(
                (response) => {
                  setComposition(response.data);
                  stopGlobalLoader();
                  initSnackbarSuccess("Composition data successfully saved");
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                },
                (err) => {
                  stopGlobalLoader();
                  initSnackbarError(err);
                }
              );
            }
            break;
          case "edit":
            API.put(
              ApiUri.COMPOSITION + "/" + newComposition.id,
              newComposition
            ).then(
              (response) => {
                setComposition(response.data);
                stopGlobalLoader();
                initSnackbarSuccess("Composition data successfully edited");
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              },
              (err) => {
                stopGlobalLoader();
                initSnackbarError(err);
              }
            );
            break;
        }
        break;
      case 1:
        API.put(
          ApiUri.COMPOSITION + "/" + currentComposition.id,
          currentComposition
        ).then(
          (response) => {
            setComposition(response.data);
            if (mode === "create") {
              API.post(
                ApiUri.COMPOSITION + "/" + currentComposition.id + ApiUri.SONG,
                {}
              ).then(
                (response) => {
                  setActiveStep(-1);
                  let songs = currentComposition.songs;
                  songs.push(response.data);
                  setComposition((previousState) => ({
                    ...previousState,
                    songs: songs,
                  }));
                  stopGlobalLoader();
                  initSnackbarSuccess(
                    "Success! Now add your Master Recordings info"
                  );
                  history.push("/songs/edit/" + songs[0].id);
                },
                (err) => {
                  stopGlobalLoader();
                  initSnackbarError(err);
                }
              );
            } else {
              setActiveStep(-1);
              if (setActiveGlobalStep) {
                setActiveGlobalStep(1);
              }
              stopGlobalLoader();
              initSnackbarSuccess("Songwriters successfully edited");
            }
          },
          (err) => {
            stopGlobalLoader();
            initSnackbarError(err);
          }
        );
        break;
    }
  };

  return (
    <Stack marginBottom={4}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <H2>Composition{composition?.title && `: "${composition?.title}"`}</H2>
        {user?.is_mmpz_service_admin && mode === "edit" ? (
          <Fab
            size="small"
            onClick={() => setDeliverySplitPreviewDialogOpen(true)}
          >
            <CallSplit />
          </Fab>
        ) : null}
      </Stack>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              onClick={() => handleStepLabelClick(index)}
              style={{
                cursor: mode === "edit" || index === 0 ? "pointer" : "default",
              }}
            >
              {label}
            </StepLabel>
            <StepContent>{getStepContent(index)}</StepContent>
          </Step>
        ))}
      </Stepper>
      {mode === "edit" && (
        <DeliverySplitsPreviewDialog
          composition={composition}
          open={deliverySplitPreviewDialogOpen}
          closeDialog={closeDeliverySplitsPreviewDialog}
        />
      )}
    </Stack>
  );
};

export default CompositionForm;
