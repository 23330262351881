import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import ProfileCommunicationSettingsTypes from "../../../../../enums/profile-communication-setting-types";
import { Profile } from "../../../../../models/profile/profile";
import { ProfileCommunicationSettings } from "../../../../../models/profile/profile-communication-settings";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { Email, Phone, Save } from "@mui/icons-material";
import { AxiosResponse } from "axios";

interface ICommunicationSettings {
  profile: Profile;
}

export default function CommunicationSettings({
  profile,
}: ICommunicationSettings) {
  const [profileCommunicationSettings, setProfileCommunicationSettings] =
    useState<ProfileCommunicationSettings[]>(
      profile.profile_communication_settings
    );

  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const [newEmail, setNewEmail] = useState<string>("");
  const [formData, setFormData] = useState<Profile>(profile);

  const handleNewEmailSubmit = (event, type: string) => {
    event.preventDefault();
    startGlobalLoader();

    const data = {
      email: newEmail,
      type: type,
    };

    API.post(
      ApiUri.PROFILE + "/" + profile.id + ApiUri.COMMUNICATION_SETTINGS,
      data
    ).then(
      (response: AxiosResponse) => {
        stopGlobalLoader();
        initSnackbarSuccess("Email successfully added");
        setNewEmail("");

        let newData = [...profileCommunicationSettings, response.data];
        setProfileCommunicationSettings(newData);
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value);
  };

  const handleDelete = (item: ProfileCommunicationSettings) => {
    startGlobalLoader();

    API.delete(
      ApiUri.PROFILE +
        "/" +
        profile.id +
        ApiUri.COMMUNICATION_SETTINGS +
        "/" +
        item.id
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Email successfully removed");

        const newData = profileCommunicationSettings.filter(
          (row) => row.id !== item.id
        );
        setProfileCommunicationSettings(newData);
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    formData.profile_information.phone_number = event.target.value;
    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const handlePhoneNumberSubmit = (event) => {
    event.preventDefault();
    startGlobalLoader();

    API.put(ApiUri.PROFILE + "/" + profile.id, formData).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Phone number successfully updated");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <Typography variant="h6" color="primary">
          General
        </Typography>
        <Grid container spacing={2}>
          {profileCommunicationSettings.map(
            (item: ProfileCommunicationSettings) =>
              item.type === ProfileCommunicationSettingsTypes.GENERAL ? (
                <Grid key={item.id} item>
                  <Chip
                    onDelete={() => handleDelete(item)}
                    label={item.email}
                  />
                </Grid>
              ) : null
          )}
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item>
              <form
                style={{ width: "100%" }}
                autoComplete="off"
                onSubmit={(event) =>
                  handleNewEmailSubmit(
                    event,
                    ProfileCommunicationSettingsTypes.GENERAL
                  )
                }
              >
                <FormControl>
                  <TextField
                    required={true}
                    type="email"
                    label="Add new"
                    placeholder="Email address..."
                    onChange={handleEmailChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            type="submit"
                            edge="end"
                            size="large"
                          >
                            <Save />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="h6" color="primary">
          Legal
        </Typography>
        <Grid container spacing={2}>
          {profileCommunicationSettings.map(
            (item: ProfileCommunicationSettings) =>
              item.type === ProfileCommunicationSettingsTypes.LEGAL ? (
                <Grid key={item.id} item>
                  <Chip onDelete={handleDelete} label={item.email} />
                </Grid>
              ) : null
          )}
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item>
              <form
                autoComplete="off"
                onSubmit={(event) =>
                  handleNewEmailSubmit(
                    event,
                    ProfileCommunicationSettingsTypes.LEGAL
                  )
                }
              >
                <FormControl>
                  <TextField
                    required={true}
                    type="email"
                    label="Add new"
                    placeholder="Email address..."
                    onChange={handleEmailChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            type="submit"
                            edge="end"
                            size="large"
                          >
                            <Save />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="h6" color="primary">
          Accounts
        </Typography>
        <Grid container spacing={2}>
          {profileCommunicationSettings.map(
            (item: ProfileCommunicationSettings) =>
              item.type === ProfileCommunicationSettingsTypes.ACCOUNTS ? (
                <Grid key={item.id} item>
                  <Chip onDelete={handleDelete} label={item.email} />
                </Grid>
              ) : null
          )}
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item>
              <form
                autoComplete="off"
                onSubmit={(event) =>
                  handleNewEmailSubmit(
                    event,
                    ProfileCommunicationSettingsTypes.ACCOUNTS
                  )
                }
              >
                <FormControl>
                  <TextField
                    required={true}
                    type="email"
                    label="Add new"
                    placeholder="Email address..."
                    onChange={handleEmailChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            type="submit"
                            edge="end"
                            size="large"
                          >
                            <Save />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="h6" color="primary">
          Phone Number
        </Typography>
        <form autoComplete="off" onSubmit={handlePhoneNumberSubmit}>
          <FormControl>
            <TextField
              required={true}
              type="text"
              label="Profile phone number"
              placeholder="Phone number..."
              onChange={handlePhoneNumberChange}
              value={profile.profile_information.phone_number ?? ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      type="submit"
                      edge="end"
                      size="large"
                    >
                      <Save />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
}
