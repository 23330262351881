import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import ApiUri from "../../../../api/api-uri";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import Compositions from "../../../../enums/compositions";
import { useState } from "react";
import { Composition } from "../../../../models/composition/composition";
import { AuthContext } from "../../../../context/auth";
import API from "../../../../api/api";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import CompositionCard from "./composition-card";
import SongwritersCard from "./songwriters/songwriters-card";
import SongsCard from "./songs-card";
import ConfirmationDialogActivator from "../../../../components/confirmation-dialog/confirmation-dialog-activator";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import UnsubmissionDialog from "./unsubmission-dialog";
import CompositionContextProvider from "../../../../context/composition-context";
import TerritoriesDialog from "./territories-dialog";
import SongWritersPercentageShareContextProvider from "../../../../context/songwriters-percentage-share";
import PlatformsDialog from "./platforms-dialog";

export default function ProcessSong() {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarGeneric, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const [unsubmissionDialogOpened, setUnsubmissionDialogOpened] =
    useState(false);
  const [territoriesDialogOpened, setTerritoriesDialogOpened] = useState(false);
  const [platformsDialogOpened, setPlatformsDialogOpened] = useState(false);
  const [composition, setComposition] = useState<Composition | null>();

  useEffect(() => {
    let isMounted = true;
    if (composition && composition.id) {
      return;
    }

    startGlobalLoader();

    API.get(ApiUri.SONG + "/" + id).then(
      (response) => {
        if (isMounted) {
          if (
            response.data.composition.status !==
              Compositions.STATUS_PROCESSING ||
            response.data.composition?.processed_by?.id !== user?.id
          ) {
            stopGlobalLoader();
            initSnackbarGeneric("You can't process this song");
            history.push("/songs/manage");
            return;
          }

          API.get(ApiUri.COMPOSITION + "/" + response.data.composition.id).then(
            (response) => {
              setComposition(response.data);
              stopGlobalLoader();
            }
          );
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );

    return () => {
      isMounted = false;
    };
  }, [composition]); // eslint-disable-line

  const handlePublish = () => {
    startGlobalLoader();

    API.post(ApiUri.COMPOSITION + "/" + composition?.id + ApiUri.PUBLISH).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Composition was successfully published");
        history.push("/songs/manage");
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const toogleUnsubmissionDialog = () => {
    setUnsubmissionDialogOpened(!unsubmissionDialogOpened);
  };

  const toogleTerritoriesDialog = () => {
    setTerritoriesDialogOpened(!territoriesDialogOpened);
  };

  const tooglePlatformsDialog = () => {
    setPlatformsDialogOpened(!platformsDialogOpened);
  };

  return composition ? (
    <React.Fragment>
      <CompositionContextProvider
        composition={composition}
        setComposition={setComposition}
      >
        <CompositionCard composition={composition} />
        <SongwritersCard
          composition={composition}
          setComposition={setComposition}
        />
        <SongsCard composition={composition} />

        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item>
            <ActionButtonSmall
              color="primary"
              onClick={toogleUnsubmissionDialog}
            >
              Unsubmit
            </ActionButtonSmall>
          </Grid>
          <Grid item>
            <ActionButtonSmall onClick={toogleTerritoriesDialog}>
              Select Territory
            </ActionButtonSmall>
          </Grid>
          <Grid item>
            <ActionButtonSmall onClick={tooglePlatformsDialog}>
              Select Platforms
            </ActionButtonSmall>
          </Grid>
          <Grid item>
            <ConfirmationDialogActivator
              onClick={handlePublish}
              actionName="Publish"
              content="Are you sure you want to publish this song?"
            />
          </Grid>
        </Grid>

        <UnsubmissionDialog
          dialogOpened={unsubmissionDialogOpened}
          onCloseDialog={toogleUnsubmissionDialog}
        />

        <SongWritersPercentageShareContextProvider composition={composition}>
          <TerritoriesDialog
            dialogOpened={territoriesDialogOpened}
            onCloseDialog={toogleTerritoriesDialog}
          />
        </SongWritersPercentageShareContextProvider>

        <PlatformsDialog
          dialogOpened={platformsDialogOpened}
          onCloseDialog={tooglePlatformsDialog}
        />
      </CompositionContextProvider>
    </React.Fragment>
  ) : null;
}
