import React, { useCallback, useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../components/loader";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import DateTime from "../../../components/utilities/date-time";
import { Box, debounce, InputAdornment, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CheckCircle, RemoveCircle, Search } from "@mui/icons-material";
// import ContextMenu from "./context-menu";
import { Profile } from "../../../models/profile/profile";
import ProfileTypes from "../../../enums/profile-types";
import ContextMenu from "./context-menu";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
// import AboutSubProfileModal from "about-sub-profile-modal/index";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

const formatDate = (date) => {
  if (date) {
    const [year, month, day] = date.split("T")[0].split("-");
    const formattedDate = `${day.padStart(2, "0")}/${month.padStart(
      2,
      "0"
    )}/${year}`;
    return formattedDate;
  } else {
    return "";
  }
};

const useStyles: any = makeStyles({
  wrappedCell: {
    wordBreak: "break-all",
  },
});

const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default function SubscriptionPaymentsTable({
  subscriptions,
  showActive,
}) {
  console.log("🚀 ~ showActive:", showActive);
  console.log("🚀 ~ subscriptions:", subscriptions);
  const classes = useStyles();
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const filteredInvoices = subscriptions.flatMap((subscription) =>
    subscription.invoices
      .filter((invoice) =>
        showActive ? invoice.status === "active" : invoice.status !== "active"
      )
      .map((invoice) => ({ subscription, invoice }))
  );

  const sortedInvoices = filteredInvoices.sort(
    (a, b) =>
      new Date(b.invoice.billing_date).getTime() -
      new Date(a.invoice.billing_date).getTime()
  );

  return (
    <React.Fragment>
      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>Date</b>
                </TableCell>
                <TableCell>
                  <b>Description</b>
                </TableCell>
                <TableCell>
                  <b>Payment Type</b>
                </TableCell>
                <TableCell>
                  <b>Transaction Number</b>
                </TableCell>
                <TableCell>
                  <b>Net Amount</b>
                </TableCell>
                <TableCell>
                  <b>Gross Amount</b>
                </TableCell>
                <TableCell>
                  <b>Currency</b>
                </TableCell>
                <TableCell>
                  <b>Invoice Status</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedInvoices.map(({ subscription, invoice }) => (
                <TableRow key={invoice.id}>
                  <TableCell>{formatDate(invoice.billing_date)}</TableCell>
                  <TableCell>
                    Payment for{" "}
                    {subscription.package?.parent
                      ? subscription.package?.parent?.name
                      : subscription.package?.name}{" "}
                    -{" "}
                    {subscription.package?.parent
                      ? subscription.package?.parent?.description
                      : subscription.package?.description}
                    ({subscription.package?.is_annual ? "12 months" : "1 month"}
                    ) - Date: {formatDate(invoice.avialable_on)} -{" "}
                    {formatDate(invoice.avialable_off)}
                  </TableCell>
                  <TableCell>
                    {subscription?.package_transaction?.manual
                      ? "Manual Invoice"
                      : subscription?.package_transaction?.payment_gateway
                          ?.payment_type?.name}
                  </TableCell>
                  <TableCell className={classes.wrappedCell}>
                    {subscription?.package_transaction?.manual
                      ? "-"
                      : subscription?.package_transaction
                          ?.payment_gateway_external_id}
                  </TableCell>
                  <TableCell>
                    {subscription.package?.parent
                      ? `£ ${subscription.package?.parent?.price.toFixed(2)}`
                      : `£ ${invoice.total.toFixed(2)}`}
                  </TableCell>
                  <TableCell>
                    {subscription.package?.parent
                      ? `£ ${subscription.package?.parent?.price.toFixed(2)}`
                      : `£ ${invoice.sub_total.toFixed(2)}`}
                  </TableCell>
                  <TableCell>GBP</TableCell>
                  <TableCell>{capitalizeFirstLetter(invoice.status)}</TableCell>
                  <TableCell>
                    <ActionButtonSmall
                      disabled={subscription?.package_transaction?.manual}
                      href={`https://api.mmpz.${
                        testSite ? "test." : ""
                      }myclientzone.com/invoice?invoice_number=${
                        invoice.number
                      }`}
                      target="_blank"
                    >
                      Download Invoice
                    </ActionButtonSmall>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}
