import { Card, CircularProgress, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext } from "../../../context/auth";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import ConfirmProductModal from "./ConfirmProductModal";

export const roundDownToSecondDecimal = (number) => {
  return number.toFixed(2);
};

const ProductCard = ({
  product,
  paymentGateways,
  available = true,
  currentSubscription = undefined,
}) => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const subProfileLimit = Number(product?.subprofile_limit) ?? 0;
  const subProfilesCount =
    Number(user?.last_accessed_profile?.sub_profiles_count) ?? 0;

  const subprofileLimitTooLow =
    subProfileLimit && subProfilesCount && subProfilesCount > subProfileLimit;

useEffect(() => {
    if (product.file) {
      API.get("/media-file" + "/" + product.file.id).then(
        (response) => {
          setImageURL(response.data.url);
          setLoading(false);
        },
        (err) => {
          initSnackbarError(err);
          setLoading(false);
        }
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <Card variant="outlined" sx={{ padding: 2 }}>
      <Stack justifyContent={"center"} spacing={2}>
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <React.Fragment>
            <img
              src={imageURL || ""}
              alt=""
              width="100%"
              style={{
                width: "100%",
                height: "150px",
                objectFit: "scale-down",
              }}
            />
            <h3>{product.name}</h3>
            <h2>
              £{" "}
              {roundDownToSecondDecimal(
                (product.discount ? 1 - product.discount : 1) * product.price
              )}
            </h2>
            <ActionButtonSmall
              onClick={() => setConfirmModalOpen(true)}
              disabled={!available}
            >
              {available ? "Select" : "Unavailable"}
            </ActionButtonSmall>
          </React.Fragment>
        )}
      </Stack>
      <ConfirmProductModal
        modalOpened={confirmModalOpen}
        handleClose={() => setConfirmModalOpen(false)}
        product={product}
        paymentGateways={paymentGateways}
        imageURL={imageURL}
        currentSubscription={currentSubscription}
      />
    </Card>
  );
};

export default ProductCard;
