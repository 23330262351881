import {
  Dialog,
  AppBar,
  Typography,
  Grid,
  Slide,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Fab,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TransitionProps } from "@mui/material/transitions";
import React, { useContext, useEffect, useState } from "react";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { Territory } from "../../../../../models/composition/territory";
import { CompositionContext } from "../../../../../context/composition-context";
import { Close, Delete } from "@mui/icons-material";
import { SongWritersPercentageShareContext } from "../../../../../context/songwriters-percentage-share";
import useTerritories from "../../../../../hooks/territory/territories.hook";
import CustomToolbar from "../../../../../components/layout/titles/CustomToolbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: "300px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  // made children required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ITerritoriesDialog {
  dialogOpened: boolean;
  onCloseDialog: Function;
}

export default function TerritoriesDialog({
  dialogOpened,
  onCloseDialog,
}: ITerritoriesDialog) {
  const classes = useStyles();
  const territories = useTerritories();
  const [selectedTerritory, setSelectedTerritory] = useState<any>();

  const { composition, setComposition } = useContext(CompositionContext);
  const { calculateAnaraSongwritersShare } = useContext(
    SongWritersPercentageShareContext,
  );
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  useEffect(() => {
    // For now, we will only be working in context of one territory
    if (composition.composition_territories.length > 0) {
      setSelectedTerritory(composition.composition_territories[0].territory);
    }
  }, []); // eslint-disable-line

  const handleFieldChange = (event: any) => {
    const id = event.target.value;
    const territory: any = territories.find(
      (territory: Territory) => territory.id === id,
    );
    addTerritory(territory);
  };

  /**
   * Add territory for composition
   *
   * @param territory
   * @returns
   */
  const addTerritory = (territory: Territory) => {
    startGlobalLoader();

    const data = {
      percentage: calculateAnaraSongwritersShare(),
    };

    API.post(
      ApiUri.COMPOSITION +
        "/" +
        composition.id +
        ApiUri.TERRITORY +
        "/" +
        territory.id,
      data,
    ).then(
      (response) => {
        stopGlobalLoader();
        setComposition(response.data);
        setSelectedTerritory(territory);
        initSnackbarSuccess("Territory added");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      },
    );
  };

  const removeTerritory = (territory: Territory) => {
    startGlobalLoader();
    API.delete(
      ApiUri.COMPOSITION +
        "/" +
        composition.id +
        ApiUri.TERRITORY +
        "/" +
        territory.id,
    ).then(
      (response) => {
        stopGlobalLoader();
        setComposition(response.data);
        setSelectedTerritory(null);
        initSnackbarSuccess("Territory removed");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      },
    );
  };

  const closeDialog = () => {
    onCloseDialog();
  };

  return (
    <Dialog
      fullScreen
      open={dialogOpened}
      onClose={closeDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <AppBar className={classes.appBar}>
        <CustomToolbar>
          <Typography variant="h6" className={classes.title}>
            Select Territory
          </Typography>

          <IconButton
            edge="end"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
            size="large"
          >
            <Close />
          </IconButton>
        </CustomToolbar>
      </AppBar>

      <Grid container spacing={2} style={{ padding: 20 }}>
        {!selectedTerritory ? (
          <Grid item xs={12}>
            <InputLabel id="select-label">Please choose...</InputLabel>
            <Select
              labelId="select-label"
              fullWidth
              name="territory.id"
              onChange={handleFieldChange}
              value={selectedTerritory?.id || ""}
            >
              {territories?.map((territory: Territory, index: number) => {
                return (
                  <MenuItem key={index} value={territory.id}>
                    {territory.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        ) : null}

        {selectedTerritory ? (
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemText>Name: {selectedTerritory?.name}</ListItemText>
                <ListItemSecondaryAction
                  onClick={() => removeTerritory(selectedTerritory)}
                >
                  <Fab size="small" color="primary">
                    <Delete />
                  </Fab>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText>
                  TIS Code:{" "}
                  {composition.composition_territories[0]?.territory?.tis_code}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Percentage:{" "}
                  {composition.composition_territories[0]?.percentage}%
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        ) : null}
      </Grid>
    </Dialog>
  );
}
