import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { GlobalLoaderContext } from "../../../context/global-loader";
import Platforms from "../../../enums/platforms";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { SnackbarContext } from "../../../context/snackbar";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import DateTime from "../../../components/utilities/date-time";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { SdCardSharp } from "@mui/icons-material";
import CompositionsAutocomplete from "./CompositionsAutocomplete";
import SmallAvatar from "../../../components/avatars/small-avatar";
import ProfileTypes from "../../../enums/profile-types";
import CompositionBox from "./CompositionBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const availablePlatforms = [
  Platforms.PRS,
  Platforms.MUSIXMATCH,
  Platforms.PRETZEL,
];

const situations = [
  { code: "NWR", name: "New" },
  { code: "REV", name: "Revised" },
];

export default function ExportCwrModal({ modalOpened, handleClose }) {
  const classes = useStyles();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null);
  const [exportAll, setExportAll] = useState(false);
  const [selectedPlatformID, setSelectedPlatformID] = useState(0);
  const [situation, setSituation] = useState<any>(situations[0]);
  const [sendToPlatform, setSendToPlatform] = useState(0);
  const [selectionMethod, setSelectionMethod] = useState<
    "range" | "compositions"
  >("range");
  const [autoCompleteValue, setAutoCompleteValue] = useState({
    id: 0,
    composition: { title: "" },
  });
  const [autoCompleteTextFieldInputValue, setAutoCompleteTextFieldInputValue] =
    useState("");
  const [selectedCompositions, setSelectedCompositions] = useState<any>([]);

  const closeExportCwrModal = () => {
    setStartDate(null);
    setEndDate(null);
    setExportAll(false);
    setSelectedPlatformID(0);
    handleClose();
  };

  const handleStartDateChange = (event) => {
    const newDate = event;
    setStartDate(newDate);
  };
  const handleEndDateChange = (event) => {
    const newDate = event.$d;
    setEndDate(newDate);
  };

  const handleExportAllChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      setStartDate(null);
      setEndDate(null);
    }
    setExportAll(checked);
  };

  const handleSendToPlatformChange = (event) => {
    const { checked } = event.target;
    setSendToPlatform(checked ? 1 : 0);
  };

  const handlePlatformChange = (event) => {
    setSelectedPlatformID(event.target.value);
  };

  const handleSituationChange = (event) => {
    const situationCode = event.target.value;
    const selectedSituation = situations.find(
      (situation) => situation.code === situationCode
    );
    setSituation(selectedSituation);
  };

  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    } else {
      // change here if the value should be different when exporting all songs
      return "";
    }
  };

  const handleSave = () => {
    // from web is hardcoded
    let compositionsIdList: any = [];
    selectedCompositions.forEach((composition) => {
      compositionsIdList.push(composition?.composition?.id);
    });
    const formData = {
      date_begin:
        selectionMethod === "range" ? formatDate(startDate?.$d) : null,
      date_end: selectionMethod === "range" ? formatDate(endDate) : null,
      from: "web",
      situation: situation.code,
      sendToPlatform: sendToPlatform,
      compositions: selectionMethod === "range" ? [] : compositionsIdList,
    };
    startGlobalLoader();
    API.post(
      ApiUri.FEED + ApiUri.CWR + "/" + selectedPlatformID,
      formData
    ).then(
      (response) => {
        if (response.data.message) {
          initSnackbarGeneric(response.data.message);
          stopGlobalLoader();
        } else {
          initSnackbarSuccess("CWR requested");
          setStartDate(null);
          setEndDate(null);
          setExportAll(false);
          setSelectedPlatformID(0);
          stopGlobalLoader();
          closeExportCwrModal();
        }
      },
      (error) => {
        initSnackbarError(error);
        setStartDate(null);
        setEndDate(null);
        setExportAll(false);
        stopGlobalLoader();
        closeExportCwrModal();
      }
    );
  };

  const handleSelectionMethodChange = (event) => {
    const { value } = event.target;
    setSelectionMethod(value);
  };

  const formIsValid =
    selectedPlatformID !== 0 &&
    (selectionMethod === "range"
      ? exportAll || (startDate && endDate)
      : selectedCompositions.length > 0);

  const onChange = (composition) => {
    if (composition?.id) {
      console.log("🚀 ~ onChange ~ composition:", composition);
      const newSelectedCompositions = selectedCompositions;
      newSelectedCompositions.push(composition);
      setSelectedCompositions(newSelectedCompositions);
      setAutoCompleteValue({
        id: 0,
        composition: { title: "" },
      });
      setAutoCompleteTextFieldInputValue("");
    }
  };

  const handleDeleteCompositionBox = (index) => {
    const newSelectedCompositions = selectedCompositions;
    newSelectedCompositions.splice(index, 1);
    setSelectedCompositions([...newSelectedCompositions]);
  };

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeExportCwrModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Export catalogue as CWR
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeExportCwrModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack marginTop={2} padding={3} spacing={2}>
          <FormControl>
            <InputLabel>Selection Method</InputLabel>
            <Select
              value={selectionMethod}
              onChange={handleSelectionMethodChange}
              label="Selection Method"
            >
              <MenuItem value={"range"} key={"range"}>
                Date Range
              </MenuItem>
              <MenuItem value={"compositions"} key={"compositions"}>
                {"Specific Song(s)"}
              </MenuItem>
            </Select>
          </FormControl>
          {selectionMethod === "range" && (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction={"row"} spacing={2}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    format="DD/MM/YYYY"
                    disabled={exportAll}
                    disableFuture
                    sx={{ width: "100%" }}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={exportAll}
                    sx={{ width: "100%" }}
                    minDate={startDate}
                  />
                </Stack>
              </LocalizationProvider>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportAll}
                    onChange={handleExportAllChange}
                    name="music_contribution"
                    color="primary"
                  />
                }
                label="Export all songs"
              />
            </>
          )}
          {selectionMethod === "compositions" && (
            <>
              <CompositionsAutocomplete
                autocompleteOnChange={onChange}
                autoCompleteValue={autoCompleteValue}
                setAutoCompleteValue={setAutoCompleteValue}
                autoCompleteTextFieldInputValue={
                  autoCompleteTextFieldInputValue
                }
                setAutoCompleteTextFieldInputValue={
                  setAutoCompleteTextFieldInputValue
                }
              />
              {selectedCompositions.length > 0 && (
                <Stack direction={"row"} spacing={2}>
                  {selectedCompositions?.map((composition, index) => (
                    <CompositionBox
                      key={composition.id}
                      composition={composition}
                      index={index}
                      handleDelete={handleDeleteCompositionBox}
                    />
                  ))}
                </Stack>
              )}
            </>
          )}
          <FormControl>
            <InputLabel>Platform</InputLabel>
            <Select
              value={selectedPlatformID}
              onChange={handlePlatformChange}
              label="Platform"
            >
              {availablePlatforms.map((platform) => (
                <MenuItem value={platform.id} key={platform.id}>
                  {platform.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Situation</InputLabel>
            <Select
              value={situation.code}
              onChange={handleSituationChange}
              label="Situation"
            >
              {situations.map((situation) => (
                <MenuItem value={situation.code} key={situation.code}>
                  {situation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendToPlatform ? true : false}
                onChange={handleSendToPlatformChange}
                name="sendToPlatform"
                color="primary"
              />
            }
            label="Send to Platform"
          />
          <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
            Export
          </ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
