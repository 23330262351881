import React, { useContext, useEffect, useState } from "react";
import NewSubProfileForm from "./new-sub-profile-form";
import { Composition } from "../../../models/composition/composition";
// import CompositionForm from "./composition-form";
import SongWritersPercentageShareContextProvider from "../../../context/songwriters-percentage-share";
import { Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { LyricsRounded, VolumeUpRounded } from "@mui/icons-material";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ProfileTypes from "../../../enums/profile-types";
import ProductsPage from "../../songs/add-new-song/ProductsPage";
import RoleTypes from "../../../enums/role-types";

export default function NewSubProfile() {
  const [showProductsPage, setShowProductsPage] = useState(false);
  const [productsAvailableForPurchase, setProductsAvailableForPurchase] =
    useState([]);
  const [showNewSubProfilesPage, setShowNewSubProfilesPage] = useState(false);

  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name !== ProfileTypes.SONGWRITER;

  const checkIfHasSomeActiveProduct = (products) => {
    return products.some((product) => product.active === true);
  };

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowNewSubProfilesPage(true);
      if (
        user?.last_accessed_profile.profile_type.name ===
          ProfileTypes.SONGWRITER ||
        user?.last_accessed_profile.profile_type.name === ProfileTypes.PUBLISHER
      ) {
        startGlobalLoader();
        API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
          (response) => {
            const profileObject = response.data;
            // check to see if parent is Anara Publishing, if so, skip all validation
            // update: this check has been removed
            if (
              // profileObject.parent.profile_information.name === "Anara Publishing"
              false
            ) {
              stopGlobalLoader();
            } else {
              const productsArray = profileObject.package_song_writers;
              const hasAtLeastOneActiveProduct =
                checkIfHasSomeActiveProduct(productsArray);
              const userRole = profileObject?.profile_rbac?.find(
                (profile) => profile.user.id === user?.id
              )?.role.name;
              if (
                (productsArray.length > 0 && hasAtLeastOneActiveProduct) ||
                userRole === RoleTypes.READ_ONLY ||
                userRole === RoleTypes.EDITOR
              ) {
                stopGlobalLoader();
              } else {
                API.get(ApiUri.PROFILE + "/" + profileObject.parent.id).then(
                  (response) => {
                    const productsArray = response.data.package_song_writers;
                    if (
                      productsArray.length > 0 &&
                      checkIfHasSomeActiveProduct(productsArray) &&
                      response.data.profile_type.name === ProfileTypes.PUBLISHER
                    ) {
                      stopGlobalLoader();
                    } else {
                      API.get(ApiUri.PACKAGE).then(
                        (response) => {
                          let allProducts = response.data.collection;
                          if (productsArray.length === 0) {
                            allProducts = allProducts.filter(
                              (product) => product.is_basic
                            );
                          }
                          const filteredProducts = allProducts.filter(
                            (product) =>
                              product.profile_type.name ===
                              user?.last_accessed_profile.profile_type.name
                          );
                          setProductsAvailableForPurchase(filteredProducts);
                          setShowProductsPage(true);
                        },
                        (err) => {
                          history.push("/dashboard");
                          initSnackbarError(err);
                          stopGlobalLoader();
                        }
                      );
                    }
                  },
                  (err) => {
                    history.push("/dashboard");
                    initSnackbarError(err);
                    stopGlobalLoader();
                  }
                );
              }
            }
          },
          (err) => {
            history.push("/dashboard");
            initSnackbarError(err);
            stopGlobalLoader();
          }
        );
      }
    }
  }, []);

  return (
    showNewSubProfilesPage && (
      <React.Fragment>
        {showProductsPage ? (
          <ProductsPage products={productsAvailableForPurchase} />
        ) : (
          <NewSubProfileForm />
        )}
      </React.Fragment>
    )
  );
}
