import React, { useCallback, useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../../components/loader";
import API from "../../../../api/api";
import { AuthContext, IAuthContext } from "../../../../context/auth";
import ApiUri from "../../../../api/api-uri";
import DateTime from "../../../../components/utilities/date-time";
import { Box, debounce, InputAdornment, TextField } from "@mui/material";
import { CheckCircle, RemoveCircle, Search } from "@mui/icons-material";
import ContextMenu from "./context-menu";
import { Profile } from "../../../../models/profile/profile";
import ProfileTypes from "../../../../enums/profile-types";
import AboutSubProfileModal from "../about-sub-profile-modal/index";
import SmallAvatar from "../../../../components/avatars/small-avatar";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import RoleTypes from "../../../../enums/role-types";
import { useHistory } from "react-router-dom";

export default function SubProfilesTable() {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const { user } = useContext<IAuthContext>(AuthContext);
  const [subProfiles, setSubProfiles] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<Profile>();
  const history = useHistory();

  useEffect(() => {
    startGlobalLoader();
    API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
      (response) => {
        const profile = response.data;
        const userRole = profile?.profile_rbac?.find(
          (profile) => profile.user.id === user?.id
        )?.role.name;
        if (
          userRole !== RoleTypes.OWNER &&
          userRole !== RoleTypes.ADMIN &&
          userRole !== RoleTypes.EDITOR &&
          !user?.is_mmpz_service_admin
        ) {
          stopGlobalLoader();
          initSnackbarError("You don't have permission to access this page.");
          history.push("/dashboard");
          return;
        }
        setCurrentUserRole(userRole);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (subProfiles) {
      return;
    }

    API.get(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        ApiUri.SUB_PROFILE,
      {
        params: {
          limit: rowsPerPage,
          page: page + 1,
          search_term: searchTerm,
        },
      }
    ).then((response) => {
      if (isMounted) {
        setTotalRows(response.data.meta.total);
        setSubProfiles(response.data.collection);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [subProfiles]); // eslint-disable-line

  const reload = () => {
    setSubProfiles(null);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setSubProfiles(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setSubProfiles(null);
  };

  const debouncedSearchTermInput = useCallback(
    debounce(() => {
      setPage(0);
      setSubProfiles(null);
    }, 800),
    []
  );

  const handleChangeSearchBy = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearchTermInput();
  };

  const handleRowCellClick = (profile: Profile) => {
    setSelectedProfile(profile);
    setModalOpened(true);
  };

  const handleModalClose = () => {
    setModalOpened(false);
  };

  return subProfiles ? (
    <React.Fragment>
      <Box mb={1} display="flex" flexDirection="row-reverse">
        <TextField
          label="Search"
          focused={true}
          value={searchTerm}
          onChange={handleChangeSearchBy}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Paper elevation={3} sx={{ marginBottom: 3 }}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <b>Profile Name</b>
                </TableCell>
                <TableCell>
                  <b>Type</b>
                </TableCell>
                <TableCell>
                  <b>Owner Name</b>
                </TableCell>
                <TableCell>
                  <b>Enabled?</b>
                </TableCell>
                {(user?.last_accessed_profile.profile_type.name ===
                  ProfileTypes.SERVICE ||
                  user?.last_accessed_profile.profile_type.name ===
                    ProfileTypes.PUBLISHER_ADMINISTRATOR ||
                  user?.last_accessed_profile.profile_type.name ===
                    ProfileTypes.PUBLISHER) && (
                  <TableCell>
                    <b>Publisher Administrator</b>
                  </TableCell>
                )}
                <TableCell>
                  <b>Location</b>
                </TableCell>
                <TableCell>
                  <b>Sub Profiles</b>
                </TableCell>
                <TableCell>
                  <b>Created At</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ cursor: "pointer" }}>
              {(subProfiles as any).map((row: Profile) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      <SmallAvatar
                        size="30px"
                        profileType={row.profile_type.name}
                      />
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row.profile_information?.name}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row.profile_type?.name}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row.owner ? row.owner.user_profile.first_name : ""}{" "}
                      {row.owner ? row.owner.user_profile.last_name : ""}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row.enabled ? (
                        <CheckCircle color="primary" />
                      ) : (
                        <RemoveCircle />
                      )}
                    </TableCell>
                    {(user?.last_accessed_profile.profile_type.name ===
                      ProfileTypes.SERVICE ||
                      user?.last_accessed_profile.profile_type.name ===
                        ProfileTypes.PUBLISHER_ADMINISTRATOR ||
                      user?.last_accessed_profile.profile_type.name ===
                        ProfileTypes.PUBLISHER) && (
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.publisherAdministrator?.profile_information
                          ?.name &&
                        row.profile_type.name !==
                          ProfileTypes.PUBLISHER_ADMINISTRATOR &&
                        row.profile_type.name !== ProfileTypes.SERVICE
                          ? row.publisherAdministrator?.profile_information
                              ?.name
                          : "-"}
                      </TableCell>
                    )}
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row.profile_information?.address}
                      <br></br>
                      {row.profile_information?.city}
                      <br></br>
                      {row.profile_information?.country.country}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      {row.profile_type?.name === ProfileTypes.SONGWRITER ? (
                        <RemoveCircle />
                      ) : (
                        row.sub_profiles_count
                      )}
                    </TableCell>
                    <TableCell onClick={() => handleRowCellClick(row)}>
                      <DateTime>{row.created_at}</DateTime>
                    </TableCell>
                    {(currentUserRole === RoleTypes.OWNER ||
                      currentUserRole === RoleTypes.ADMIN ||
                      user?.is_mmpz_service_admin) && (
                      <TableCell>
                        <ContextMenu reload={reload} profile={row} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <AboutSubProfileModal
        modalOpened={modalOpened}
        handleClose={handleModalClose}
        profileId={selectedProfile?.id}
        currentUserRole={currentUserRole}
      />
    </React.Fragment>
  ) : (
    <Loader message="Loading sub profiles" />
  );
}
