import React, { useContext, useState } from "react";
import { Link, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom";
import { Song } from "../../../../models/song/song";
import Compositions from "../../../../enums/compositions";
import { AuthContext } from "../../../../context/auth";
import ConfirmationDialogActivator from "../../../../components/confirmation-dialog/confirmation-dialog-activator";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { SnackbarContext } from "../../../../context/snackbar";
import ProfileTypes from "../../../../enums/profile-types";
import UnsubmitSongModal from "./UnsubmitSongModal";
import PlatformsDialog from "./PlatformsDialog";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

interface IContextMenu {
  song: Song;
  setSongs: any;
}

export default function ContextMenu({ song, setSongs }: IContextMenu) {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unsubmitModalOpened, setUnsubmitModalOpened] = useState(false);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const [platformsDialogOpened, setPlatformsDialogOpened] = useState(false);

  const handleContextClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContext = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    history.push("/songs/edit/" + song.id);
  };

  const handleContinueProcessingClick = () => {
    history.push("/songs/process/" + song.id);
  };

  const handleStopProcessingClick = () => {
    startGlobalLoader();
    API.post(
      ApiUri.COMPOSITION + "/" + song.composition.id + ApiUri.SUBMIT
    ).then(
      (response) => {
        setSongs(null);
        stopGlobalLoader();
        initSnackbarSuccess("Successfully stopped processing song");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const handleProcessClick = () => {
    startGlobalLoader();

    API.post(
      ApiUri.COMPOSITION + "/" + song.composition.id + ApiUri.PROCESS
    ).then(
      (response) => {
        stopGlobalLoader();
        history.push("/songs/process/" + song.id);
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const handleTakeDownClick = () => {
    startGlobalLoader();

    API.post(
      ApiUri.COMPOSITION + "/" + song.composition.id + ApiUri.TAKE_DOWN
    ).then(
      (response) => {
        setSongs(null);
        stopGlobalLoader();
        initSnackbarSuccess("Song successfully taken down");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const handlePublishClick = () => {
    startGlobalLoader();

    API.post(
      ApiUri.COMPOSITION + "/" + song.composition.id + ApiUri.PUBLISH
    ).then(
      (response) => {
        setSongs(null);
        stopGlobalLoader();
        initSnackbarSuccess("Song successfully published");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const handleDeleteClick = () => {
    startGlobalLoader();

    API.delete(
      ApiUri.COMPOSITION + "/" + song.composition.id + ApiUri.DELETE
    ).then(
      (response) => {
        setSongs(null);
        stopGlobalLoader();
        initSnackbarSuccess("Song successfully deleted");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const songCanBeSubmitted = async (composition) => {
    const form1CompositionIsValid =
      composition.title &&
      (composition.is_instrumental ||
        (composition.lyrics &&
          composition?.composition_languages?.length > 0)) &&
      composition.title_language;

    if (!form1CompositionIsValid) {
      return false;
    }

    let totalShares = 0;

    for (const songwriter of composition.song_writers) {
      totalShares += songwriter.share;
      if (
        !songwriter.id ||
        !songwriter.share ||
        !songwriter.songwriter_cae_number ||
        !songwriter.publisher_name ||
        !songwriter.publisher_cae_number ||
        (!songwriter.music_contribution && !songwriter.lyrics_contribution) ||
        !songwriter.pro_affiliation ||
        (!songwriter.external_song_writer && !songwriter.profile)
      ) {
        return false;
      }
    }
    if (totalShares !== 100) {
      return false;
    }

    const moreThanOneSong = composition.songs.length > 0;

    if (!moreThanOneSong) {
      return false;
    }

    const apiCalls = composition.songs.map((song) => {
      return API.put(ApiUri.SONG + "/" + song.id, song).then((response) => {});
    });

    try {
      await Promise.all(apiCalls);
    } catch (error) {
      return false;
    }

    return true;
  };

  const handleSubmitClick = () => {
    startGlobalLoader();

    API.get(ApiUri.COMPOSITION + "/" + song.composition.id)
      .then((response) => {
        const completeComposition = response.data;
        return songCanBeSubmitted(completeComposition);
      })
      .then((canBeSubmitted) => {
        if (canBeSubmitted) {
          API.post(
            ApiUri.COMPOSITION + "/" + song.composition.id + ApiUri.SUBMIT
          ).then(
            (response) => {
              setSongs(null);
              stopGlobalLoader();
              initSnackbarSuccess("Successfully submitted the song");
            },
            (err) => {
              stopGlobalLoader();
              initSnackbarError(err);
            }
          );
        } else {
          initSnackbarError("The song can't be submitted in its current state");
          stopGlobalLoader();
        }
      })
      .catch((err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      });
  };

  const tooglePlatformsDialog = () => {
    setPlatformsDialogOpened(!platformsDialogOpened);
  };

  const showDelete = () => {
    for (let i = 0; i < song.composition.songwriter_names.length; i++) {
      const songwriter = song.composition.songwriter_names[i];
      for (let j = 0; j < user!.main_roles.length; j++) {
        const role = user?.main_roles[j];
        if (
          role!.profile_name.includes(songwriter) &&
          (role!.role === "Editor" || role!.role === "Admin")
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const buttons = {
    showEditButton:
      song.composition.status === Compositions.STATUS_DRAFT ||
      (song.composition.status === Compositions.STATUS_PUBLISHED &&
        user?.is_mmpz_service_admin &&
        user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE),
    showSubmitButton:
      song.composition.status === Compositions.STATUS_DRAFT &&
      user?.is_mmpz_service_admin &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE,
    showUnsubmitButton:
      song.composition.status === Compositions.STATUS_SUBMITTED &&
      user?.is_mmpz_service_admin &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE,
    showProcessButton:
      song.composition.status === Compositions.STATUS_SUBMITTED &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE,
    showSelectPlatformsAndTakeDownButtons:
      song.composition.status === Compositions.STATUS_PUBLISHED &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE &&
      user.is_mmpz_service_admin,
    showPublishOrRepublishButton:
      (song.composition.status === Compositions.STATUS_TAKEN_DOWN ||
        song.composition.status === Compositions.STATUS_PROCESSING) &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE,
    showDeleteButton:
      song.composition.status === Compositions.STATUS_DRAFT &&
      (user?.is_mmpz_service_admin || showDelete()),
    showContinueProcessingButton:
      song.composition.status === Compositions.STATUS_PROCESSING &&
      Number(song.composition.processed_by?.id) === user?.id,
    showStopProcessingButton:
      song.composition.status === Compositions.STATUS_PROCESSING &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE,
    showViewSongwriterButton:
      user?.is_mmpz_service_admin &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE,
    showDownloadCSVButton:
      (user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE &&
        (song?.composition.status === Compositions.STATUS_TAKEN_DOWN ||
          song?.composition.status === Compositions.STATUS_SUBMITTED ||
          song?.composition.status === Compositions.STATUS_PROCESSING ||
          song?.composition.status === Compositions.STATUS_PUBLISHED)) ||
      song?.composition.status === Compositions.STATUS_PROCESSING ||
      song?.composition.status === Compositions.STATUS_PUBLISHED,
  };

  const hideMenu = Object.values(buttons).every((value) => value === false);

  const testSite =
    window.location.href.includes("test") ||
    window.location.href.includes("localhost");

  const handleVisitSongwriterClick = () => {
    startGlobalLoader();
    API.get(
      ApiUri.SELECT_PROFILE + "/" + song.composition.profileOwner.id
    ).then(
      (response) => {
        window.location.href = "/";
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <React.Fragment>
      {hideMenu ? null : (
        <React.Fragment>
          <MoreVertIcon
            onClick={handleContextClick}
            className={classes.menuIcon}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseContext}
          >
            <MenuItem sx={{ display: "none" }}></MenuItem>
            {buttons.showViewSongwriterButton && (
              <MenuItem onClick={handleVisitSongwriterClick}>
                Visit Songwriter
              </MenuItem>
            )}

            {buttons.showEditButton && (
              <MenuItem onClick={handleEditClick}>Edit</MenuItem>
            )}

            {buttons.showSubmitButton && (
              <MenuItem key="submit" onClick={handleSubmitClick}>
                Submit
              </MenuItem>
            )}

            {buttons.showDownloadCSVButton && (
              <MenuItem key="downloadCSV">
                <Link
                  style={{ textDecoration: "none", color: "inherit" }}
                  href={
                    `https://api.mmpz.${
                      testSite ? "test." : ""
                    }myclientzone.com/api/v1` +
                    ApiUri.SONG +
                    "/" +
                    song.id +
                    ApiUri.DOWNLOAD
                  }
                >
                  Download CSV
                </Link>
              </MenuItem>
            )}

            {buttons.showUnsubmitButton && (
              <MenuItem
                onClick={() => setUnsubmitModalOpened(true)}
                key="unsubmit"
              >
                Unsubmit
              </MenuItem>
            )}

            {buttons.showProcessButton && (
              <MenuItem key="process">
                <ConfirmationDialogActivator
                  actionName="Process"
                  content="Are you sure you want to pick up this song for processing?"
                  onClick={handleProcessClick}
                  type="link"
                />
              </MenuItem>
            )}

            {buttons.showSelectPlatformsAndTakeDownButtons && [
              <MenuItem onClick={tooglePlatformsDialog} key="select-platforms">
                Select Platforms
              </MenuItem>,
              <MenuItem key="take down">
                <ConfirmationDialogActivator
                  actionName="Take Down"
                  content="Are you sure you want to take down this song?"
                  onClick={handleTakeDownClick}
                  type="link"
                />
              </MenuItem>,
            ]}

            {buttons.showPublishOrRepublishButton && (
              <MenuItem>
                <ConfirmationDialogActivator
                  actionName={
                    song.composition.status === Compositions.STATUS_TAKEN_DOWN
                      ? "Republish"
                      : "Publish"
                  }
                  content="Are you sure you want to publish this song?"
                  onClick={handlePublishClick}
                  type="link"
                />
              </MenuItem>
            )}

            {buttons.showDeleteButton && (
              <MenuItem>
                <ConfirmationDialogActivator
                  actionName="Delete"
                  content="Are you sure you want to delete this song?"
                  onClick={handleDeleteClick}
                  type="link"
                />
              </MenuItem>
            )}

            {buttons.showContinueProcessingButton && (
              <MenuItem onClick={handleContinueProcessingClick}>
                Continue Processing
              </MenuItem>
            )}

            {buttons.showStopProcessingButton && (
              <MenuItem onClick={handleStopProcessingClick}>
                Stop Processing
              </MenuItem>
            )}
          </Menu>

          <UnsubmitSongModal
            modalOpened={unsubmitModalOpened}
            handleClose={() => setUnsubmitModalOpened(false)}
            compositionId={song.composition.id}
            setSongs={setSongs}
          />

          {platformsDialogOpened && (
            <PlatformsDialog
              dialogOpened={platformsDialogOpened}
              onCloseDialog={tooglePlatformsDialog}
              compositionId={song.composition.id}
              setSongs={setSongs}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
