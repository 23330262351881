import React, { useContext } from "react";
import { Box, Fab, Grid, IconButton, Menu, Tooltip } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AuthContext, IAuthContext } from "../context/auth";
import {
  GlobalLoaderContext,
  IGlobalLoaderContext,
} from "../context/global-loader";
import { CustomisationContext } from "../context/customisation";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

export default function AppSelector() {
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader } =
    useContext<IGlobalLoaderContext>(GlobalLoaderContext);
  const { customisation } = useContext(CustomisationContext);
  const [serviceAppMenuAnchorEl, setServiceAppMenuAnchorEl] =
    React.useState(null);

  const handleServiceAppMenuClick = (event: any) => {
    setServiceAppMenuAnchorEl(event.currentTarget);
  };

  const handleServiceAppMenuClose = () => {
    setServiceAppMenuAnchorEl(null);
  };

  const handleAppSelect = () => {
    handleServiceAppMenuClose();
    startGlobalLoader();
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        color="inherit"
        onClick={handleServiceAppMenuClick}
        size="large"
      >
        <AppsIcon sx={{ color: customisation?.textColor }} />
      </IconButton>

      <Menu
        anchorEl={serviceAppMenuAnchorEl}
        keepMounted
        open={Boolean(serviceAppMenuAnchorEl)}
        onClose={handleServiceAppMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid container direction="row">
          <Box
            padding={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <a
              onClick={handleAppSelect}
              style={{ textDecoration: "none" }}
              href={
                `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
                "/dashboard"
              }
            >
              <Tooltip title="My Client Zone Account" placement="top">
                <Fab>
                  <img
                    src="/mmaz-logo.png"
                    alt="MMAZ Logo"
                    style={{ height: "100%" }}
                  />
                </Fab>
              </Tooltip>
            </a>
            <h5 style={{ fontWeight: 900 }}>ACCOUNT</h5>
          </Box>
          {user?.can_access_mmdz ? (
            <Box
              padding={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <a
                onClick={handleAppSelect}
                style={{ textDecoration: "none" }}
                href={`https://mmdz.${testSite ? "test." : ""}myclientzone.com`}
              >
                <Tooltip title="My Music Distribution Zone" placement="top">
                  <Fab>
                    <img
                      src="/mmdz-logo.png"
                      alt="MMDZ Logo"
                      style={{ height: "100%" }}
                    />
                  </Fab>
                </Tooltip>
              </a>
              <h5 style={{ fontWeight: 900 }}>MMDZ</h5>
            </Box>
          ) : null}
        </Grid>
      </Menu>
    </Box>
  );
}
